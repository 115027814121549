body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .events-list{
  display: flex;
  width:100%;
  height: 100%;
} */
/* iframe {
  /* display:grid; 
  border: 0;
width: 100%;
height: 100%;
} */

.events-list {
  display: block;
  width: 100%;
  height: 550px;
  /* padding: 30px; */
  border: 2px solid gray;
  overflow: hidden;
  position: relative;
}
iframe {
  width: 100%;
  height: 100%;
}